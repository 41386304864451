import React, { Component, createRef } from "react";
import Arrow from "../assets/arrow.inline.svg";
import parse from "html-react-parser";
import Slider from "react-slick";
import Img from "gatsby-image";
import "../sass/pages/_staff.scss";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import { BiMessageSquareAdd, BiMessageSquareMinus } from "react-icons/bi";
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";
import Seo from "../components/seo";
import { graphql } from "gatsby";

gsap.registerPlugin(SplitText);

class Staff extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
    currentColor: "bg--light-blue",
    currentPerson: "colin",
    currentlyActive: null,
    staffCount: this.props.data.wpPage.acf_staff.staffMembers.length,
    staffLoadedCount: 0,
  };

  constructor(props) {
    super(props);
    this.sliderWrap = React.createRef();
    this.hoverOverlay = React.createRef();
  }

  componentDidMount() {
    if (typeof document !== "undefined") {
      document.querySelector("body").setAttribute("data-bg-color", "bg--light-blue");
      document.querySelector("body").setAttribute("data-class", "");
    }

    // if url has query string then find the staff member and scroll to it
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const staffMember = urlParams.get("staffMember");
      if (staffMember) {
        const staffMemberElement = document.querySelector(
          `.staff-pagination li[data-slug="${staffMember}"]`
        );

        const staffFullName = staffMemberElement.getAttribute("data-staffName");

        const slideTarget = document.querySelector(
          '.staff-section[data-name="' + staffFullName + '"]'
        );

        // click the pagination item to trigger the slider to move to the correct slide
        staffMemberElement.click();
        setTimeout(() => {
          slideTarget.classList.add("active-slide");
        }, 500);
      }
    }

    let splittext;
    if (typeof document !== "undefined") {
      // let text = heroText.innerHTML;
      var split = new SplitText(".staff-section h2 .split", {
        type: "words",
        wordsClass: "lines",
      });

      split.words.map((line) => {
        let grandparent = line.parentNode.parentNode;
        let parent = line.parentNode;
        let originalHtml = line.innerHTML;
        let newHtml = `<div>${originalHtml}</div>`;

        line.innerHTML = newHtml;

        grandparent.append(line);
        Array.from(grandparent.querySelectorAll(".lines")).map((line) => {
          setTimeout(
            function () {
              line.classList.add("is_inview");
            }.bind(this),
            1
          );
        });
        parent.style.display = "none";
      });
    }
  }

  handleClick(e) {
    if (typeof document !== "undefined") {
      if (window.innerWidth <= 960) {
        const arrows = Array.from(document.querySelectorAll(".arrow"));
        const header = document.querySelector("#header");
        const activeSlide = document.querySelector(".slick-active .active-slide .staff-info");
        const activeSlideImg = document.querySelector(".slick-active .active-slide .staff-img");
        const target = e.target;

        const window = document.documentElement.clientHeight;
        const bioHeight = activeSlide.querySelector(".centered").clientHeight;
        const activeSlideImgHeight = activeSlideImg.clientHeight;

        const elements = gsap.utils.toArray([
          "#body",
          ".slick-slider",
          ".slick-list",
          ".slick-track",
          ".staff-section",
        ]);
        // console.log(window, document.documentElement.clientHeight)

        if (activeSlide.classList.contains("active")) {
          // gsap.to(activeSlideImg, {height:'90%', duration:0})
          gsap.to(elements, { height: window, duration: 0 });
          this.hoverOverlay.current.classList.remove("active");
          setTimeout(function () {
            activeSlide.classList.remove("active");
            header.classList.remove("hidden");
            arrows.map((arrow) => arrow.classList.remove("behind"));
          }, 500);
        } else {
          arrows.map((arrow) => arrow.classList.add("behind"));
          activeSlide.classList.add("active");
          this.hoverOverlay.current.classList.add("active");
          header.classList.add("hidden");
          setTimeout(function () {
            // gsap.to(activeSlideImg, {height:activeSlideImgHeight})
            gsap.to(elements, { height: window / 2 + bioHeight });
          }, 500);
        }
      }
    }
  }

  checkIfAllImageAreLoaded() {
    this.setState({
      staffLoadedCount: this.state.staffLoadedCount + 1,
    });
  }

  render() {
    const staff = this.props.data.wpPage.acf_staff.staffMembers;

    var settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      swipe: false,
      touchMove: false,
      focusOnSelect: true,
      autoplaySpeed: 5000,
      arrows: false,
      // initialSlide: 2,

      afterChange: (index) => {
        this.setState((state) => ({
          updateCount: state.updateCount + 1,
        }));

        const slides = this.sliderWrap.current.querySelectorAll(`.staff-section`);

        setTimeout(
          function () {
            slides[index].classList.add("active-slide");
          }.bind(this),
          250
        );
      },

      onInit: () => {
        setTimeout(
          function () {
            // const initialSlide = this.sliderWrap.current.querySelector('.staff-section:nth-child(1)')
            // initialSlide.classList.add('active-slide');
          }.bind(this),
          100
        );
      },

      beforeChange: (current, next) => {
        // set index state
        this.setState({ slideIndex: next });

        const slidesArr = this.sliderWrap.current.querySelectorAll(`.staff-section`);

        slidesArr.forEach((slide) => slide.classList.remove("active-slide"));

        let slides = Array.from(document.querySelectorAll(".slick--staff .staff-section"));

        let currentSlideIndex = next;

        const currentSlide = slides[currentSlideIndex].attributes;
        const bgColors = Array.from(document.querySelectorAll(".bg"));
        const pagination = Array.from(document.querySelectorAll(".staff-pagination ul li"));
        const paginationWrapper = document.querySelector(".staff-pagination");
        const currentColor = currentSlide[4].nodeValue;
        const currentPerson = currentSlide[3].nodeValue;

        // Update bg shape animations
        const bgWrap = document.querySelector(".bg-rect-wrap");
        bgWrap.setAttribute("data-animate", `step${currentSlideIndex + 1}`);

        // Update background color
        bgColors.map((bg) => {
          paginationWrapper.setAttribute("data-bg-color", currentColor.substring(3));
          document.querySelector("body").setAttribute("data-bg-color", currentColor.substring(3));
          document.querySelector("#body").setAttribute("data-bg-color", currentColor.substring(3));
          if (bg.classList.contains(currentColor)) {
            bg.classList.add("active");
          } else {
            bg.classList.remove("active");
          }
        });

        // Update pagination
        pagination.map((person) => {
          if (person.getAttribute("data-person") === currentPerson) {
            person.classList.add("active");
          } else {
            person.classList.remove("active");
          }
        });
      },
    };

    return (
      <>
        <Seo
          title={this.props.data.wpPage.seo.title}
          description={this.props.data.wpPage.seo.metaDesc}
          date={this.props.data.wpPage.date}
          slug={"/" + this.props.data.wpPage.slug + "/"}
          robotsFollow={this.props.data.wpPage.seo.metaRobotsNofollow}
          robotsIndex={this.props.data.wpPage.seo.metaRobotsNoindex}
          canonicalUrl={this.props.data.wpPage.seo.canonical}
        />
        {this.props.data.wpPage.seo.schema.raw !== undefined ? (
          <SchemaLinksToAbsolute schemaRaw={this.props.data.wpPage.seo.schema.raw} />
        ) : (
          ""
        )}
        <div
          id="body"
          className="staff page-template-staff"
          data-bg-color="bg--light-blue"
          ref={this.sliderWrap}>
          {staff.map((staff, i) => {
            return (
              <div
                className={
                  i === 0 ? " bg active bg-" + staff.colour : "bg bg-" + staff.colour
                }></div>
            );
          })}

          <div className="staff-pagination" data-bg-color="bg--light-blue">
            <ul>
              {staff.map((staff, i) => {
                return (
                  <li
                    data-person={staff.firstNameLowercase}
                    className={i === 0 ? "active" : ""}
                    data-slug={`${staff.name.toLowerCase().replace(/\s/g, "-")}`}
                    data-staffName={staff.name}
                    data-slide-index={i + 1}
                    data-color={staff.colour}
                    onClick={() => {
                      this.slider.slickGoTo(i);
                    }}></li>
                );
              })}
            </ul>
          </div>

          <div className="bg-rect-wrap" data-animate="step1">
            {staff.map((staff, i) => {
              return (
                <div className={`step${i + 1} wrap`}>
                  <div className="bg-rect"></div>
                </div>
              );
            })}
          </div>
          <div className="arrow-wrap">
            <div
              className="arrow prev"
              value={this.state.slideIndex}
              onClick={() => {
                this.slider.slickGoTo(this.state.slideIndex - 1);
              }}>
              <Arrow />
            </div>
            <div
              className="arrow next"
              value={this.state.slideIndex}
              onClick={() => {
                this.slider.slickGoTo(this.state.slideIndex + 1);
              }}>
              <Arrow />
            </div>
          </div>
          <div className="hover" ref={this.hoverOverlay} onClick={(e) => this.handleClick(e)}></div>

          <Slider
            className="staff-wrapper slick--staff"
            ref={(slider) => (this.slider = slider)}
            {...settings}>
            {staff.map((staff, i) => {
              return (
                <section
                  id={staff.firstNameLowercase}
                  className={`${
                    this.state.staffLoadedCount === this.state.staffCount && i === 0
                      ? "active-slide"
                      : ""
                  } staff-section slide staff-group`}
                  data-name={staff.name}
                  // data-target={`${staff.name.toLowerCase().replace(/\s/g, "-")}`}
                  data-person={staff.firstNameLowercase}
                  data-bg-color={"bg-" + staff.colour}
                  data-bg-animate={"step" + i + 1}>
                  <div className="staff-img">
                    {staff?.image?.localFile?.childImageSharp?.fluid ? "" : "image not found"}
                    <Img
                      onLoad={() => this.checkIfAllImageAreLoaded()}
                      loading={true}
                      fluid={staff?.image?.localFile?.childImageSharp?.fluid}
                      className="bg-cover BG bgStretch"
                    />
                  </div>
                  <div className="staff-info" data-bg-color={staff.colour}>
                    <div className="centered">
                      <div className="label">
                        {/* {this.state.staffCount} | {this.state.staffLoadedCount} */}
                        {staff.postition}
                        {staff.nickname ? " |" + staff.nickname : ""}
                      </div>
                      <div className="heading-wrapper">
                        <h2>
                          <div className="split">{staff.name}</div>
                        </h2>
                        <BiMessageSquareAdd className="read-more plus" />
                        <BiMessageSquareMinus className="read-more minus" />
                      </div>
                      <div className="bio">{parse(staff.biography)}</div>
                    </div>
                  </div>
                </section>
              );
            })}
          </Slider>
        </div>
      </>
    );
  }
}

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDoxNA==" }) {
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema {
          raw
        }
      }
      date
      slug
      acf_staff {
        staffMembers {
          biography
          colour
          fieldGroupName
          firstNameLowercase
          name
          nickname
          postition
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default Staff;
