import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ 
  description, 
  lang, 
  meta, 
  title,
  page,
  date,
  image,
  slug,
  ogWidth,
  ogHeight,
  robotsFollow,
  robotsIndex,
  canonicalUrl
}) => {
 

  const query = useStaticQuery(graphql`
    query SeoQuery {
      wp {
        myOptionsPage {
          acf_options {
            defaultOgTwitterImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const defaultImage = process.env.GATSBY_SITE_URL+query.wp.myOptionsPage.acf_options.defaultOgTwitterImage.localFile.publicURL

    const metaDescription = description
    const seoTitle = title

    const tester = {
      seoTitle: seoTitle,
      metaDescription: metaDescription,
    }

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={seoTitle}
        link = {[
          { rel : 'canonical', href : canonicalUrl === slug || canonicalUrl === '/' ? process.env.GATSBY_SITE_URL+slug : canonicalUrl }
        ]}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `robots`,
            content: `${robotsIndex}, ${robotsFollow}`,
          },
          {
            property: `og:locale`,
            content: 'en_GB',
          },
          {
            property: `og:type`,
            content: page === 'homepage' ? `website` : 'article',
          },
          {
            property: `og:title`,
            content: seoTitle,
            // content: seoTitle.length >= 50 ? seoTitle.substring(0, 50) + '...' : seoTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:url`,
            content: process.env.GATSBY_SITE_URL+slug,
          },
          {
            property: `og:site_name`,
            content: 'Edge45®',
          },
          {
            property: `article:publisher`,
            content: process.env.GATSBY_SITE_URL,
          },
          {
            property: `article:published_time`,
            content: date,
          },
          {
            property: `article:modified_time`,
            content: date,
          },
          {
            property: `og:image`,
            content: image ? image : defaultImage,
          },
          {
            property: `og:image:width`,
            content: page === 'blogPost' || page === 'caseStudyPost' ? ogWidth : `400`,
          },
          {
            property: `og:image:height`,
            content: page === 'blogPost' || page === 'caseStudyPost' ? ogHeight : `400`
          },
          {
            name: `twitter:card`,
            content: page === 'blogPost' || page === 'caseStudyPost' ? 'summary_large_image' : `summary`,
          },
          {
            name: `twitter:title`,
            content: seoTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: image ? image : defaultImage,
          },
        ].concat(meta)}
      />
    )
  }
  
  Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
  }
  
  Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
  }
  
  export default Seo
  